import React, { useState, useRef, useEffect, useContext } from 'react';

import {Context1, ChatContext} from './Context1';
import {BuildNewConversation, UpdateConversation} from './ChatManagement'
import API from './API';
import './ChatUI.css';



const ChatUI = () => {
    // const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const textAreaRef = useRef(null);
    const {ChatOptionDropdownEnable, setChatOptionDropdownEnable, ChatPageEnable, setChatPageEnable, DataAnalysisFiles, setDataAnalysisFiles } = useContext(Context1);
    const {masterData, Conversation, setConversation, AllChatHistory, setAllChatHistory} = useContext(ChatContext);
    const chatLogRef = useRef(null);


    useEffect(() => {
        if (chatLogRef.current) {
        chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
        }
    }, [Conversation.chat["message"]]);
    
    useEffect(() => {
        if (textAreaRef.current) {
        textAreaRef.current.style.height = '4.8vh';
        // textAreaRef.current.style.height = '48px';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        const root = document.documentElement;
        root.style.setProperty('--textbox-height', `${textAreaRef.current.scrollHeight+10}px`);
        console.log(textAreaRef.current.style.height)
        }
    }, [input]);

    useEffect(() => {
        const handleResize = () => {
            const zoomLevel = window.devicePixelRatio;
            // const Screenwidth = window.innerWidth * zoomLevel/1920; // 30% of screen width
            const ScreenResolutionAvailableW = window.innerWidth
            const ScreenResolutionAvailableH = window.innerHeight
            let ScreenResolutionW;
            let ScreenResolutionH;

            if (window.screen.orientation['type']=='landscape-primary'){
                // ScreenResolutionW= window.screen.width
                ScreenResolutionH= window.screen.height
                // if the available screen is less than horizontal height (the window is squished in width)else wthe width will follow the height (make the window square)
                if (ScreenResolutionAvailableW < ScreenResolutionH){
                    ScreenResolutionW = ScreenResolutionAvailableW;
                }else{ScreenResolutionW = ScreenResolutionH;}
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                document.documentElement.style.setProperty('--dynamic-widthFull', `${ScreenResolutionH}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionAvailableH}px`);
                // console.log("a",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
            }else{
                ScreenResolutionH= window.screen.height
                ScreenResolutionW= window.screen.width
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionH}px`);
                // console.log("b",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
            }
            // console.log(zoomLevel,ScreenResolutionW,ScreenResolutionH)
            // console.log(DataAnalysisFiles);
            document.documentElement.style.setProperty('--dynamic-Zoomlevel', `${zoomLevel}`);
        };
        // Attach the event listener
        window.addEventListener('resize', handleResize);
        // Call handleResize to set the initial size
        handleResize();
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

   
    const SetRewriteLastMessage = (MessagesHistory, newMessage) => {
        // Check if there are any messages in the array
        if (MessagesHistory.length === 0) {
        console.log("first messages to update");
        if (newMessage!==''){
            const combinedMessages = [ ...MessagesHistory, newMessage];
            return combinedMessages
        }
        }else{
        if (MessagesHistory[MessagesHistory.length-1]['isOwnMessage']===true){
            // Update the last message in the array
            const combinedMessages = [...MessagesHistory]; // Make a shallow copy of the existing messages array
            combinedMessages[MessagesHistory.length-1] = newMessage; // Update the last element
            return combinedMessages
        }else{
            const combinedMessages = [ ...MessagesHistory, newMessage];
            return combinedMessages
        }
        } 
    };


    const sendMessage = async () => {
        if (input.trim() === '') {
        console.log("empty");
        }else{

        const MessageTime = new Date();
        const currentMessageTimeISO = MessageTime.toISOString();
        
        const userMessage = await { message: input, isOwnMessage: true, timestamp:currentMessageTimeISO };

        const MessagesHistory = [...Conversation.chat["message"]]
        const combinedMessages=SetRewriteLastMessage(MessagesHistory, userMessage)

        const ChatLog={
            chat : {
            ConversationID:  Conversation.chat["ConversationID"],
            UserName: Conversation.chat["UserName"],
            message: [...combinedMessages], 
            SystemPrompt: Conversation.chat["SystemPrompt"],
            Model: Conversation.chat["Model"],
            Version: Conversation.chat["Version"]}
            }

        setConversation(ChatLog);
        setInput('');
        const replyMessage = await API('https://server.entrosystem.com/api/chat',ChatLog);
        // const replyMessage = await API('/api/chat',ChatLog);

        if (replyMessage.reply===''){
            console.log("empty");
        }else{
            const ReplyTime = new Date();
            const currentReplyTimeISO = ReplyTime.toISOString();

            const MessagesWithReply={
            chat : {
            ConversationID:  Conversation.chat["ConversationID"],
            UserName: Conversation.chat["UserName"],
            message: [...combinedMessages, { message: replyMessage.reply, isOwnMessage: false, timestamp:currentReplyTimeISO }], 
            SystemPrompt: Conversation.chat["SystemPrompt"],
            Model: Conversation.chat["Model"],
            Version: Conversation.chat["Version"]}
            }
            setConversation(MessagesWithReply);
        }
        }
        
    };

    const selectEmoticon = (emoticon) => {
        setInput(input + emoticon);
    };

    const BackToProfile = () => {
        // This function contains a methood to store current chat (const [Conversation, setConversation]) to [AllChatHistory, setAllChatHistory] 
        // then emptying (const [Conversation, setConversation])

        const {allChatHistory, ConversationID_Output}=UpdateConversation(AllChatHistory, Conversation.chat)
        setAllChatHistory(allChatHistory)
        setChatPageEnable(!ChatPageEnable)
    }
    
    const EmojiButton = ({ emoji, onClick }) => (
        <button onClick={() => onClick(emoji)}>{emoji}</button>
      );

    const SendButton = ({ onClick }) => (
        <button onClick={onClick} style={{ border: 'none', background: 'transparent' }}>
            <img className='SendButton' src={'path/Profile/asset/send.png'} style={{ border: 'none', background: 'transparent' }} alt="Send"/>
        </button>
    );

    return (
        <div className="chat-container">
            <div className='BackToProfile' onClick={BackToProfile}></div>
            <div className='TopBanner'></div>

            {!ChatOptionDropdownEnable && <div className='ChatOption'  onClick={() => setChatOptionDropdownEnable(!ChatOptionDropdownEnable) } > </div>}
            {ChatOptionDropdownEnable && <ChatOptionDropdown/>}


            <div className="chat-log" ref={chatLogRef}>
                {Conversation.chat["message"].map((msg, index) => <ChatMessage key={index} 
                message={msg.message} 
                isOwnMessage={msg.isOwnMessage} 
                time = {new Date(msg.timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                />)}
            </div>
            <div className="chat-input-section">
                {/* <div className="chat-emoticonbutton-section" style={{}}><EmojiButton emoji='😀' onClick={selectEmoticon} /></div> */}
                <div className="chat-emoticonbutton-section" onClick={selectEmoticon}></div>
                <div style={{}}>
                    <textarea ref={textAreaRef} value={input} onChange={(e) => setInput(e.target.value)} 
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            sendMessage();
                        }}}
                    className="chat-textarea" />
                </div>
                <div className='SendButton' onClick={sendMessage} />
            </div>
        </div>
    );
};
export default ChatUI;










export const ChatOptionDropdown = () => {
    const {ChatOptionDropdownEnable, setChatOptionDropdownEnable, SummarizeBoxEnable, setSummarizeBoxEnable, DataAnalysisFiles, setDataAnalysisFiles } = useContext(Context1);
    const {Conversation, setConversation, AllChatHistory, setAllChatHistory} = useContext(ChatContext);


    // Function templates for submenu actions
    const NewChat = () => {
        const {allChatHistory, ConversationID_Output}=UpdateConversation(AllChatHistory, Conversation.chat)
        setAllChatHistory(allChatHistory);

        const NewConversation={
            chat : {
            ConversationID: Conversation.chat["ConversationID"]+1,
            UserName: Conversation.chat["UserName"],
            message: [],
            SystemPrompt: "Currently Not Implemented",
            Model: Conversation.chat["Model"],
            Version: "Currently Not Implemented"}
            }
        setConversation(NewConversation)
    };

    const ChatHistory = () => {
        console.log("Action 2 performed");
        // console.log(AllChatHistory);
        console.log(AllChatHistory);
        // Add your code for Action 2
    };

    const handleAction3 = () => {
        // Add your code for Action 3
        console.log("Action 3 performed");
    };

    const OpenSummarizeBox = () => {
        setSummarizeBoxEnable(true);
        console.log("Action 4 performed", SummarizeBoxEnable);
        // Add your code for Action 4
    };

    const handleAction5 = () => {
        console.log("Action 5 performed");
        // Add your code for Action 5
    };

    const handleSummaryBoxClick = () => {
        // setChatOptionDropdownEnable(!ChatOptionDropdownEnable);
        // setSummarizeBoxEnable(false);
    };


    // const [uploadedFile, setUploadedFile] = useState(null);
    const handleFileUpload = (file) => {
        // console.log("data analysis")
        uploadPDF();
        setSummarizeBoxEnable(false);
        setChatOptionDropdownEnable(!ChatOptionDropdownEnable);

        // setDataAnalysisFiles(file);
      // Do something with the file
    };

    if (SummarizeBoxEnable==true){
        return (
            // <div style={{height:'10%', width:'20%', backgroundColor:'black', zIndex: '9999', position:'absolute', right:'0px', top:'0px' }} onClick={() => handleSummaryBoxClick() }>
            <div style={{ width: '40%', height:'30%',opacity:'0.9', backgroundColor:'black', zIndex: '9000', position:'relative', left: '30%', top:'30%' }} onClick={() => handleSummaryBoxClick() }>
                
                {/* <h1 style={{color:'white'}}>File Upload Demo</h1>
                <FileUpload onFileUpload={handleFileUpload} /> */}

                {/* <input type="file" id="customFileInput" accept=".pdf,.doc,.docx,.txt"/> */}
                <FileInput/>
                <button onClick={handleFileUpload}>Upload PDF</button>

                {/* onFileUpload currently didn't work */}
            </div>
            // </div>
        )

    }else{
        return (
            // <div className='ChatOptionDropdownMenu' onClick={() => setChatOptionDropdownEnable(!ChatOptionDropdownEnable) }>
            <div className='ChatOptionDropdownMenu'>
                <div className='Submenu' onClick={NewChat}>New Chat</div>
                <div className='Submenu' onClick={ChatHistory}>Chat History</div>
                <div className='Submenu' onClick={OpenSummarizeBox}>Summarize</div>
                <div className='Submenu' onClick={handleAction3}>Submenu 4</div>
                <div className='Submenu' onClick={handleAction5}>Submenu 5</div>
            </div>
        );
    }

};








export async function uploadPDF() {
    const pdfInput = document.getElementById("customFileInput");
    const pdfFile = pdfInput.files[0];
  
    if (!pdfFile) {
      alert("Please select a Document file.");
      return;
    }




    // const chunkID = generateUniqueID(); // A function to generate a unique chunk identifier
    const fileID = "123456"; // Identifier for the entire file, usually determined when the upload starts
    const sequenceNumber = 1; // This is the first chunk, so its sequence number is 1, placeholder for multi chunk files
    // const userID = getUserID(); // A function to get the authenticated user's ID
    const processingInstructions = "Document Vectorization"; // The type of processing needed

    const metadata = {
    // chunkID,
    fileID,
    sequenceNumber,
    // userID,
    processingInstructions
    };
  
    const formData = new FormData();
    formData.append("Document", pdfFile);
    formData.append('PacketMetadata', JSON.stringify(metadata));
  
    const options = {
      contentType: "multipart/form-data",
    };
  
    try {
    //   const response = await API("/api2/document", formData, options);
      const response = await API('https://server.entrosystem.com/api2/document', formData, options);
      console.log("Document upload successful", response);
    } catch (error) {
      console.error("Document upload failed", error);
    }
  }





  export const FileInput = () => {
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        console.log(`Selected file: ${file.name}`);
      }
    };
  
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%', border: '1px solid #ccc' }}>
        <input 
          type="file" 
          id="customFileInput" 
          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
          accept=".pdf,.doc,.docx,.txt" 
          onChange={handleFileChange}
        />
        {/* <img 
          src="path/to/your/icon.png" 
          alt="upload icon" 
          style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        /> */}
      </div>
    );
  };
  











// Placeholder image URLs for user and other person
// const userPlaceholderImage = 'path/FilmReel/1.jpg';  // Replace this with user's profile picture URL
// const otherPlaceholderImage = 'path/FilmReel/5.jpg';  // Replace this with other person's profile picture URL

// Style for the message text box
const messageStyle = {
    display: 'inline-block',
    padding: '15px',
    // maxWidth: '760px',
    maxWidth: '80%',
    backgroundColor: '#E6E6E6',
    wordWrap: 'break-word',
    borderRadius: '12px',
    fontSize: '18px',
    fontFamily: '"Arial", sans-serif',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)'
};

// Style for the profile picture
const profilePicStyle = {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    margin: '0 10px'
};

// Style for the timestamp
const timeStyle = {
    fontSize: '12px',
    color: 'white',
    textAlign: 'center'
};

export const ChatMessage = ({ message, isOwnMessage, time }) => {
    const {Conversation} = useContext(ChatContext);
    const otherPlaceholderImage = 'path/Profile/profile/'+Conversation.chat["Model"]+".jpg";
    const userPlaceholderImage = 'path/Profile/profile/user.jpg';

    return(
    // Flexbox layout to align items and justify the content
    <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
        alignItems: 'center',
        margin: '10px 0'
    }}>
        {/* If the message is from the other person, show their profile picture and timestamp */}
        {!isOwnMessage && (
        <div style={{ alignItems: 'center' }}>
            <img src={otherPlaceholderImage} alt="Other's Profile" style={profilePicStyle} />
            <div style={timeStyle}>{time}</div>
        </div>
        )}
        {/* The message content */}
        <div style={isOwnMessage ? { ...messageStyle, backgroundColor: '#E6E6E6' } : { ...messageStyle, backgroundColor: '#AFAFAF' }}>
        {message}
        </div>
        {/* If the message is from the user, show their profile picture and timestamp */}
        {isOwnMessage && (
        <div style={{ alignItems: 'center' }}>
            <img src={userPlaceholderImage} alt="User's Profile" style={profilePicStyle} />
            <div style={timeStyle}>{time}</div>
        </div>
        )}
    </div>
    )
};












// export const FileUpload = () => {
//     // State variable to hold the uploaded file
//     const {DataAnalysisFiles, setDataAnalysisFiles } = useContext(Context1);
//     const [file, setFile] = useState(null);


//     const SendDataAnalysisFile = async (file) => {
//         const options = {contentType: "multipart/form-data",};

//         const formData = new FormData();
//         // formData.append("pdfDocument", file);
        
//         // const Response = await API('/api/DataAnalysis', formData, options);
//         const Response = await API('/api2/pdf', file, options);
//         console.log("res",Response)
//         return Response;
//       }


//     // Function to handle file selection via input dialog
//     const handleFileSelect = (e) => {
//         const selectedFile = e.target.files[0];
//         setFile(selectedFile);
//         setDataAnalysisFiles(selectedFile);

//         const data = SendDataAnalysisFile(selectedFile);
//         // const parsedData = JSON.parse(data.reply);


//         // console.log(selectedFile, parsedData)
//         console.log(DataAnalysisFiles)
//     };

//     // Function to handle file drop
//     const handleDrop = (e) => {
//         e.preventDefault();
//         const droppedFile = e.dataTransfer.files[0];
//         setFile(droppedFile);
//         setDataAnalysisFiles(droppedFile);

//         const data = SendDataAnalysisFile(droppedFile);
//         // const parsedData = JSON.parse(data.reply);

//         // console.log(droppedFile,parsedData)
//     };

//     // Function to handle drag over
//     const handleDragOver = (e) => { e.preventDefault(); };
//     // Function to handle drag enter
//     const handleDragEnter = (e) => {e.preventDefault();};

//     return (
//         <div>
//             <div onDrop={handleDrop} onDragOver={handleDragOver} onDragEnter={handleDragEnter} style={{zIndex: '9001', backgroundColor:'gray',top: '0px',position:'relative',width: "100%",height: "180px",color: 'rgb(255, 255, 255)', 
//             // border: "1px solid red",
//             }}> Drag and Drop File Here </div>
//             <input type="file" onChange={handleFileSelect} />
//             {file && <p style={{color:'wheat'}}>Selected file: {file.name}</p>}
            
//         </div>
//     );
// };





