import React, {useRef, useEffect, useState, useContext} from 'react';
import {Context1, ChatContext} from './Context1';
import {BuildNewConversation, UpdateConversation} from './ChatManagement'
import styled from 'styled-components';
import './CharacterProfile.css';



const CharacterProfile = () => {
    const [currentProfileIndexParent, setcurrentProfileIndexParent] = useState(0);
    const {ChatPageEnable, setChatPageEnable, CurrentlySelectedCharacter, setCurrentlySelectedCharacter, currentProfileIndex, setcurrentProfileIndex} = useContext(Context1);
    const {masterData, Conversation, setConversation, AllChatHistory, setAllChatHistory} = useContext(ChatContext);
    const handleSquareClick = (index) => {
        // alert(`You clicked square ${index + 1}`);
        
        setCurrentlySelectedCharacter(index);
        setcurrentProfileIndexParent(index);
        // setcurrentProfileIndexParent()
        // console.log(index)
    };

    useEffect(() => {
        const handleResize = () => {
            const zoomLevel = window.devicePixelRatio;
            // const Screenwidth = window.innerWidth * zoomLevel/1920; // 30% of screen width
            const ScreenResolutionAvailableW = window.innerWidth
            // const ScreenResolutionAvailableH = window.innerHeight
            const ScreenResolutionAvailableH = window.visualViewport.height;
            let ScreenResolutionW;
            let ScreenResolutionH;

            if (window.screen.orientation['type']=='landscape-primary'){
                // ScreenResolutionW= window.screen.width
                ScreenResolutionH= window.screen.height
                // if the available screen is less than horizontal height (the window is squished in width)else wthe width will follow the height (make the window square)
                if (ScreenResolutionAvailableW < ScreenResolutionH){
                    ScreenResolutionW = ScreenResolutionAvailableW;
                }else{ScreenResolutionW = ScreenResolutionH;}
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                document.documentElement.style.setProperty('--dynamic-widthFull', `${ScreenResolutionH}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionAvailableH}px`);
                console.log("a",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
            }else{
                ScreenResolutionH= window.screen.height
                ScreenResolutionW= window.screen.width
                document.documentElement.style.setProperty('--dynamic-width', `${ScreenResolutionW}px`);
                document.documentElement.style.setProperty('--dynamic-height', `${ScreenResolutionH}px`);
                console.log("b",window.screen.orientation['type'], ScreenResolutionW,ScreenResolutionH )
            }
            // console.log(zoomLevel,ScreenResolutionW,ScreenResolutionH)
            
            document.documentElement.style.setProperty('--dynamic-Zoomlevel', `${zoomLevel}`);
        };
        // Attach the event listener
        window.addEventListener('resize', handleResize);
        // Call handleResize to set the initial size
        handleResize();
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleCharacterProfilePageClick = () => {

        // This part build new chat when the user click at character Profiler, it will load last saved conversation if the  character has daved interaction.
        // It will build empty chat if there has been never an interaction
        const SelectedCharacter=masterData[1]["characters_list"][currentProfileIndex]
        const NewConversation =BuildNewConversation(Conversation, AllChatHistory, SelectedCharacter)
        // console.log("CharacterProfile.js", NewConversation, masterData, SelectedCharacter)
        
        setConversation(NewConversation)
        setChatPageEnable(!ChatPageEnable);
    }
    return (
        <div className="boxx">
            {/* This compontnt if clicked will bring user from profile page to the chat page, by setChatPageEnable(!ChatPageEnable), 
            this will disable profile page and enable chat, this command is located in App.js */}
            <div className="CharacterSelectorWrapper" onClick={handleCharacterProfilePageClick}>
                <CharacterSelector2 
                currentProfileIndexParent={currentProfileIndexParent}
                currentProfileIndex={currentProfileIndex} 
                setcurrentProfileIndex={setcurrentProfileIndex} 
                />
                </div>
            <div className="FilmReelWrapper">
                <FilmReel onSquareClick={handleSquareClick} />
                </div>
        </div>
    );
};

export default CharacterProfile;




const ScrollContainer = styled.div`
    display: flex;
    overflow: hidden;
    width: calc((var(--dynamic-width)));
    height: calc((var(--dynamic-width)) *0.2);
    cursor: grab;
    &:active {
        cursor: grabbing;
    }
`;

const Square = styled.div`
    width:  calc((var(--dynamic-width)) *0.2);
    height: calc((var(--dynamic-width)) *0.2);
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FilmReel = ({ onSquareClick }) => {
    const scrollContainerRef = useRef(null);
    let initialPosition = 0;
    const {masterData} = useContext(ChatContext);
    const {currentProfileIndex, setcurrentProfileIndex} = useContext(Context1);



    const onMouseDown = (e) => {
        initialPosition = e.clientX;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const onMouseMove = (e) => {
        // e.clientX is mouse horizontal position
        const currentPosition = e.clientX;
        const distance = currentPosition - initialPosition;
        scrollContainerRef.current.scrollLeft -= distance;
        // console.log(currentPosition)
        initialPosition = currentPosition;
    };

    
    useEffect(() => {
        const initialPosition = (240*currentProfileIndex);
        if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollLeft = initialPosition;
        }
    }, []); // The empty dependency array ensures this runs once when the component mounts

    const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    };

    useEffect(() => {
        // Cleanup function to remove event listeners if component unmounts
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, []);

    if (masterData===null){
        return <div>Loading...</div>;
    }else{


        return (
            <ScrollContainer ref={scrollContainerRef} onMouseDown={onMouseDown}>
                {[...Array(masterData[1]["characters_list"].length)].map((_, index) => (
                    <Square key={index} onClick={() => onSquareClick(index)}>
                        <img src={'path/Profile/profile/'+masterData[1]["characters_list"][index]+'.jpg'} alt="description" draggable="false" style={{width: '100%', height: '100%'}}/>  
                    </Square>        
                ))}
            </ScrollContainer>
        );
    }
};



export const CharacterSelector2 = ({currentProfileIndexParent, currentProfileIndex, setcurrentProfileIndex}) => {
    const [opacity, setOpacity] = useState(1);
    const {CurrentlySelectedCharacter, setCurrentlySelectedCharacter } = useContext(Context1);
    const {masterData, Conversation, setConversation} = useContext(ChatContext);

    const SelectImage = () => {
        if (currentProfileIndex!==CurrentlySelectedCharacter){
            setOpacity(0);
            setTimeout(() => {
                setcurrentProfileIndex((prevIndex) => CurrentlySelectedCharacter);
                setOpacity(0.9);
            }, 100);
        }
    };

    useEffect(() => {SelectImage();},[CurrentlySelectedCharacter]);


    if (masterData===null){return <div>Loading...</div>;}
    else{
        const CurrentProfile=masterData[0]["characters"][currentProfileIndex]
        return (
            <div className="box" style={{ opacity: opacity }} >
                <div className="content">
                    {/* THis swhows character profile picture and their text profile */}
                    <div className="image-wrapper"><img className="fade" src={'path/Profile/profile/'+masterData[1]["characters_list"][currentProfileIndex]+'.jpg'} /></div>
                    <div className='TextWrapper1'><p>{CurrentProfile[masterData[1]["characters_list"][currentProfileIndex]]["profile"]}</p></div>
                    <div className='TextWrapper2'><p>{CurrentProfile[masterData[1]["characters_list"][currentProfileIndex]]["name"]}</p></div>
                </div>
            </div>
        );
    }
};