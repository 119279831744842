// start only the frontend :npm start
// When i run "npm run build" all the js and ConfigData.json will be bundled in main.js(build/static/js folder)
// CI=false npm run build

import React, { createContext, useContext, useState, useEffect } from 'react';
import CharacterProfile from './CharacterProfile';
import ChatUI from './ChatUI';
import {Context1, ChatContext} from './Context1';
import ConfigData from './ConfigData.json'; // Update the path to where your JSON file is located

function App() {
    const { ChatPageEnable } = useContext(Context1);
    const {masterData, setMasterData} = useContext(ChatContext);
    useEffect(() => {setMasterData(ConfigData);}, []);

    return (
    <div className="app" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
        { !ChatPageEnable && < CharacterProfile /> }
        { ChatPageEnable && < ChatUI />}
    </div>
    );
}
export default App;