import React, { createContext, useState, useEffect } from 'react';
export const Context1 = createContext();
export const Context1Provider = ({ children }) => {
  const [state1, setState1] = useState('initialState1');
  const [ChatOptionDropdownEnable, setChatOptionDropdownEnable] = useState(false);
  const [SummarizeBoxEnable, setSummarizeBoxEnable] = useState(false);
  const [ChatOptionEnable, setChatOptionEnable] = useState(true);
  const [ChatPageEnable, setChatPageEnable] = useState(false);
  const [CurrentlySelectedCharacter, setCurrentlySelectedCharacter] = useState(1);
  const [currentProfileIndex, setcurrentProfileIndex]=useState(0);
  const [DataAnalysisFiles, setDataAnalysisFiles]=useState(null)
  
  // ... other states
  return (
    <Context1.Provider value={{ 
        ChatOptionDropdownEnable, setChatOptionDropdownEnable, 
        ChatOptionEnable,setChatOptionEnable,
        ChatPageEnable, setChatPageEnable,
        CurrentlySelectedCharacter, setCurrentlySelectedCharacter,
        currentProfileIndex, setcurrentProfileIndex,
        SummarizeBoxEnable, setSummarizeBoxEnable,
        DataAnalysisFiles, setDataAnalysisFiles
        }}>
      {children}
    </Context1.Provider>
  );
};



// impement two level chat storag sytem

export const ChatContext = createContext();
export const ChatContextProvider = ({ children }) => {
  // Initialize state with localStorage value or default value
  const AllChatHistoryTemplate = {
    characters: [
      {
        // ModelID: 'char_1',
        Model: 'Character1',
        conversations: [
          {
            ConversationID: 'conv_1',
            SystemPrompt: 'Placeholder',
            Model: 'Character1',
            Version: 'Placeholder',
            UserName: 'Master',
            message: [
              {
                message: 'Hi', isOwnMessage: true, timestamp:'2023-08-31T12:00:00Z'
              },
              {
                message: 'Hello!', isOwnMessage: false, timestamp:'2023-08-31T12:01:00Z'
              }
            ]
          },
          // More conversations for 'char_1'
        ]
      },
      // More characters  
    ],
    CurrentChat: [{
      // ModelID : 'none',
      Model : 'none',
      conversationID : 'none'
    }]
  }
  const ChatLog={
    chat : {
    ConversationID:  "Placeholder",
    UserName: 'Master',
    message: [], 
    SystemPrompt: "Placeholder",
    Model: "Placeholder",
    Version: "Placeholder"}
    }
  // const initialConversation = JSON.parse(localStorage.getItem('Conversation')) || ChatLog;
  const initialConversation = ChatLog;


  const AllChatHistoryInitialization = JSON.parse(localStorage.getItem('AllChatHistory')) || AllChatHistoryTemplate;
  // const AllChatHistoryInitialization = JSON.parse(localStorage.getItem('AllChatHistory'));
  // const AllChatHistoryInitialization = AllChatHistoryTemplate;

  const [Conversation, setConversation] = useState(initialConversation);
  const [AllChatHistory, setAllChatHistory] = useState(AllChatHistoryInitialization);
  const [masterData, setMasterData] = useState(null);
  // console.log(AllChatHistory);

  // Function to save context state to localStorage
  const saveStateToLocalStorage = () => {
    // localStorage.setItem('state1', JSON.stringify(state1));
    localStorage.setItem('Conversation', JSON.stringify(Conversation));
  };


  const saveAllChatHistoryStateToLocalStorage = () => {
    // localStorage.setItem('state1', JSON.stringify(state1));
    // console.log(AllChatHistory); 
    console.log("save AllChatHistory");
    console.log(AllChatHistory)
    localStorage.setItem('AllChatHistory', JSON.stringify(AllChatHistory));
  };

  // Save state to localStorage whenever it changes
  useEffect(() => {
    // Individual chat no longer constantly saved in favor of saving all chat history, 
    // instead individual chat will load from all history for every profile click
    // saveStateToLocalStorage();
    console.log("save conv")
  }, [Conversation]);
  
  useEffect(() => {
    saveAllChatHistoryStateToLocalStorage();
    // console.log("supose to save")
  }, [AllChatHistory]);


  // setInterval(saveStateToLocalStorage, 60000);
  // Save state to localStorage when the user closes or navigates away from the page
  // useEffect(() => {
  //   window.addEventListener('unload', saveStateToLocalStorage);
  //   return () => {
  //     window.removeEventListener('unload', saveStateToLocalStorage);
  //   };
  // }, []);

  return (
    <ChatContext.Provider value={{Conversation, setConversation, AllChatHistory, setAllChatHistory, masterData, setMasterData}}>
      {children}
    </ChatContext.Provider>
  );
};




// export const ChatContext = createContext();
// export const ChatContextProvider = ({ children }) => {
//   // Initialize state with localStorage value or default value
//   const ChatLog={
//     chat : {ConversationID:  "Placeholder",
//     message: [], 
//     SystemPrompt: "Placeholder",
//     Model: "Placeholder",
//     Version: "Placeholder"}
//     }
//   const initialConversation = JSON.parse(localStorage.getItem('Conversation')) || ChatLog;
//   const [Conversation, setConversation] = useState(initialConversation);
//   // Function to save context state to localStorage
//   const saveStateToLocalStorage = () => {
//     // localStorage.setItem('state1', JSON.stringify(state1));
//     localStorage.setItem('Conversation', JSON.stringify(Conversation));
//   };
//   // Save state to localStorage whenever it changes
//   useEffect(() => {
//     saveStateToLocalStorage();
//     console.log("save conv")
//   }, [Conversation]);
//   return (
//     <ChatContext.Provider value={{Conversation, setConversation}}>
//       {children}
//     </ChatContext.Provider>
//   );




// const AllChatHistoryTemplate = {
//   characters: [
//     {
//       characterID: 'char_1',
//       characterName: 'Character1',
//       conversations: [
//         {
//           conversationID: 'conv_1',
//           SystemPrompt: 'Placeholder',
//           Model: 'Placeholder',
//           Version: 'Placeholder',
//           messages: [
//             {
//               sender: 'user',
//               text: 'Hi',
//               timestamp: '2023-08-31T12:00:00Z'
//             },
//             {
//               sender: 'char_1',
//               text: 'Hello!',
//               timestamp: '2023-08-31T12:01:00Z'
//             }
//           ]
//         },
//         // More conversations for 'char_1'
//       ]
//     },
//     // More characters  
//   ],
//   CurrentChat: [{
//     characterID : 'none',
//     characterName : 'none',
//     conversationID : 'none'
//   }]
// };





