export function UpdateConversation(allChatHistoryD, ToBeSavedConversation, SelectedCharacter=null) {
    // Have to make a deep copy otherwise react will not recognise changes in allChatHistory, thus not triggering Useeffect that store this object 
    const allChatHistory=JSON.parse(JSON.stringify(allChatHistoryD));
    // const allChatHistoryB=ToBeSavedConversation;
    // console.log(allChatHistory);
    const characterModel=ToBeSavedConversation.Model;
    const ConversationID=ToBeSavedConversation.ConversationID;
    let ConversationID_Output=null;
    const character = allChatHistory.characters.find(char => char.Model === characterModel);
    if (character) {
    // const SpecificConversation=character.conversations.find(conver => conver.conversationID === conversationID);
    const SpecificConversation=character.conversations.findIndex(conver => conver.ConversationID === ConversationID);
    console.log(SpecificConversation)
    if (SpecificConversation !== -1 ){
        console.log("there is existing conversation ID, Updating it")
        character.conversations[SpecificConversation] = ToBeSavedConversation;
        ConversationID_Output=ConversationID

    }else{
        // console.log("ConversationID_Output",ConversationID_Output+1+ConversationID)
        console.log("no existing Conversation ID, add new conversation to this character")
        character.conversations.push(ToBeSavedConversation);
        ConversationID_Output=ConversationID+1
    }
    }else{
    console.log('new character, adding it to new character section')
    ConversationID_Output=1
    allChatHistory.characters.push(
        {
        Model: characterModel,
        conversations: [ToBeSavedConversation]
        }
    )
    }

    // const SelectedCharacterConst=SelectedCharacter
    // const SelectedCharacterConst2 = allChatHistory.characters.find(char => char.Model === SelectedCharacterConst);

    // console.log(ConversationID_Output)
    return {
    allChatHistory,
    ConversationID_Output
    }
}




export function BuildNewConversation(Conversationn, allChatHistoryD, SelectedCharacter){
    // This part build new chat when the user click at character Profiler, it will load last saved conversation if the  character has daved interaction.
    // It will build empty chat if there has been never an interaction
    const allChatHistory=JSON.parse(JSON.stringify(allChatHistoryD))
    let NewConversation=null
    const character = allChatHistory.characters.find(char => char.Model === SelectedCharacter);
    // console.log("is character exist?",character)
    if (character){
        // console.log(character.conversations[character.conversations.length-1])
        NewConversation={
        chat : character.conversations[character.conversations.length-1]
        }
        
        // console.log("it exist")
    }else{
        // console.log("it does not")
        NewConversation={
        chat : {
        ConversationID: 1,
        UserName: SelectedCharacter,
        message: [],
        SystemPrompt: "Currently Not Implemented",
        Model: SelectedCharacter,
        Version: "Currently Not Implemented"}
        }
    }
    return NewConversation
}
