// const API = async (endpoint, payload, options = {}) => {
//     const {
//         maxRetries = 10,
//         retryDelay = 2000,
//         timeout = 5000,
//     } = options;

//     let retries = 0;

//     while (retries < maxRetries) {
//         try {
//         const response = await fetch(endpoint, {
//             method: 'POST',
//             body: JSON.stringify(payload),
//             headers: {
//             'Content-Type': 'application/json',
//             },
//             timeout,
//         });

//         if (!response.ok) {
//             throw new Error(`Network response was not ok: ${response.statusText}`);
//         }

//         const data = await response.json();
//         return data;
//         } catch (error) {
//         console.error(`Fetch error: ${error}`);
//         retries++;
//         if (retries < maxRetries) {
//             await new Promise(res => setTimeout(res, retryDelay));
//         }
//         }
//     }

//     return null;
// };

// export default API;







// Function to handle JSON payload
const handleJsonPayload = (payload) => {
    return JSON.stringify(payload);
};

// Function to handle FormData payload
const handleFormDataPayload = (payload) => {
    return payload; // Assume payload is FormData object
};

// // Function to create headers
// const createHeaders = (contentType) => {
//     return {
//         'Content-Type': contentType,
//     };
// };

// Function to create headers
const createHeaders = (contentType) => {
    if (contentType === 'multipart/form-data') {
      return {
      };
    }
    return {
      'Content-Type': contentType,
    };
  };

const API = async (endpoint, payload, options = {}) => {
    const {
        maxRetries = 10,
        retryDelay = 2000,
        timeout = 5000,
        contentType = 'application/json',
    } = options;

    let retries = 0;

    while (retries < maxRetries) {
        try {
            console.log(contentType)
            const headers = createHeaders(contentType);
            let body;

            if (contentType === 'application/json') {
                body = handleJsonPayload(payload);
            } else if (contentType === 'multipart/form-data') {
                body = handleFormDataPayload(payload);
                console.log("API",body)
            } else {
                body = payload; // Other content types can be handled here
            }

            const response = await fetch(endpoint, {
                method: 'POST',
                body,
                headers,
                timeout,
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            let data = contentType === 'application/json' ? await response.json() : await response.blob();
            return data;
        } catch (error) {
            console.error(`Fetch error: ${error}`);
            retries++;
            if (retries < maxRetries) {
                await new Promise((res) => setTimeout(res, retryDelay));
            }
        }
    }

    return null;
};

export default API;


// About fetch api call
// The fetch API call in the provided code snippet is an example of a stateless, one-off HTTP request. By "stateless," we mean that each request from the client to the server is independent; the server doesn't maintain any state information between individual requests from the same client. This is a characteristic of the HTTP protocol itself.

// Here's the basic sequence of events for the code snippet:
// Client → Server: The client (your React frontend) makes a POST request to the server. This request includes the payload and headers. If it's an HTTPS connection, an SSL/TLS handshake is performed before this step.

// Server → Client: The server processes the POST request, executes any server-side logic, and sends a response back to the client.

// Client: The client receives the response and proceeds with whatever logic is programmed to handle the response (not shown in your code snippet).

// Regarding back-and-forths:
// HTTP/HTTPS: In the context of a single HTTP request-response cycle, there's essentially one main back-and-forth: the client sends a request, and the server sends back a response. If the connection is over HTTPS, you can add the additional back-and-forths for the SSL/TLS handshake.

// Retries: Your code includes a retry mechanism (maxRetries), so if the initial request fails, the same sequence will be repeated, including the handshake if it's HTTPS.

// Statelessness: Each retry would be stateless in nature, meaning the server doesn't remember the failed request when the client retries. Each request is independent.

// In summary, each execution of the fetch function in your code results in one main client-server-client cycle for HTTP or adds a few more back-and-forths for the SSL/TLS handshake if it's HTTPS. Retries would repeat this cycle from scratch.